<!-- Topbar Start -->
<div class="navbar-custom" [class]="cssClasses">
    <div [class]="hideLogo ? '' : 'container-fluid'">
        <!-- LOGO -->
        <a routerLink="/" class="topnav-logo" *ngIf="!hideLogo">
            <span class="topnav-logo-lg">
                <img src="assets/images/logo/logo.svg" alt="" height="70">
            </span>
            <span class="topnav-logo-sm">
                <img src="assets/images/logo/logo.svg" alt="" height="70">
            </span>
        </a>

        <!-- LOGO -->
        <!-- <a routerLink="/" class="topnav-logo" *ngIf="!hideLogo && !topbarDark">
            <span class="topnav-logo-lg">
                <img src="assets/images/logo/logo.svg" alt="" height="16">
            </span>
            <span class="topnav-logo-sm">
                <img src="assets/images/logo/logo.svg" alt="" height="16">
            </span>
        </a> -->

        <ul class="list-unstyled topbar-menu float-end mb-0">
            <li class="dropdown notification-list d-xl-none" ngbDropdown autoClose="outside">
                <a ngbDropdownToggle class="nav-link dropdown-toggle arrow-none" id="mobileDropdown"
                    href="javascript: void(0);" aria-expanded="false">
                    <i class="dripicons-search noti-icon"></i>
                </a>
                <div ngbDropdownMenu aria-labelledby="mobileDropdown" class="dropdown-menu-animated dropdown-lg p-0">
                    <form ngbDropdownItem class="p-3">
                        <input type="text" class="form-control" placeholder="Search ..."
                            aria-label="Recipient's username">
                    </form>
                </div>
            </li>

            <!-- <li class="dropdown notification-list topbar-dropdown"
                [class]="layoutType!=='vertical' ? 'd-none d-lg-block' : ''" ngbDropdown placement="bottom-end">
                <a ngbDropdownToggle class="nav-link arrow-none" id="languageDropdown" href="javascript: void(0);"
                    aria-expanded="false">
                    <img [src]="selectedLanguage?.flag" [alt]="selectedLanguage?.name" class="me-0 me-sm-1" height="12">
                    <span class="align-middle d-none d-sm-inline-block">{{ selectedLanguage?.name }}</span>
                    <i class="mdi mdi-chevron-down d-none d-sm-inline-block align-middle"></i>
                </a>
                <div ngbDropdownMenu aria-labelledby="languageDropdown"
                    class="dropdown-menu-animated topbar-dropdown-menu dropdown-menu-end">
                    <a ngbDropdownItem *ngFor="let language of languages;" href="javascript:void(0);"
                        class="notify-item" (click)="changeLanguage(language)">
                        <img [src]="language.flag" [alt]="language.name" class="me-1" height="12">
                        <span class="align-middle">{{language.name }}</span>
                    </a>
                </div>
            </li> -->
            <!-- Notification -->
            <!-- <li class="dropdown notification-list" ngbDropdown placement="bottom-end">
                <a ngbDropdownToggle class="nav-link arrow-none" id="notificationDropdown" href="javascript: void(0);"
                    aria-expanded="false">
                    <i class="dripicons-bell noti-icon"></i>
                    <span class="noti-icon-badge"></span>
                </a>
                <div ngbDropdownMenu aria-labelledby="notificationDropdown"
                    class="dropdown-menu-end dropdown-menu-animated dropdown-lg">
                    
                    <div ngbDropdownItem class="noti-title px-3">
                        <h5 class="m-0">
                            <span class="float-end">
                                <a href="javascript: void(0);" class="text-dark">
                                    <small>Clear All</small>
                                </a>
                            </span>Notification
                        </h5>
                    </div>

                    <ngx-simplebar style="max-height: 300px;" class="px-3">
                        <ng-container *ngFor="let item of notificationList">
                            <h5 class="text-muted font-13 fw-normal mt-0">{{item.day}}</h5>
                            
                            <a href="javascript:void(0)" class=" p-0 notify-item card shadow-none mb-2" ngbDropdownItem
                                *ngFor="let notification of item.messages"
                                [class]="notification.isRead ? 'read-noti' : 'unread-noti'">
                                <div class="card-body">
                                    <span class="float-end noti-close-btn text-muted"><i
                                            class="mdi mdi-close"></i></span>
                                    <div class="d-flex align-items-center">
                                        <div class="flex-shrink-0">
                                            <div class="notify-icon bg-{{notification.variant}}"
                                                *ngIf="notification.icon">
                                                <i [class]="notification.icon"></i>
                                            </div>
                                        </div>
                                        <div class="flex-grow-1 text-truncate ms-2">
                                            <h5 class="noti-item-title fw-semibold font-14">{{notification.title}}
                                                <small class="fw-normal text-muted ms-1">{{notification.time}}</small>
                                            </h5>
                                            <small class="noti-item-subtitle text-muted">{{notification.text}}</small>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </ng-container>
                        <div class="text-center">
                            <i class="mdi mdi-dots-circle mdi-spin text-muted h3 mt-0"></i>
                        </div>
                    </ngx-simplebar>
                    
                    <a ngbDropdownItem href="javascript:void(0);"
                        class="text-center text-primary notify-item border-top border-light py-2">
                        View All
                    </a>
                </div>
            </li> -->
            <!-- <li class="dropdown notification-list d-none d-sm-inline-block" ngbDropdown>
                <a ngbDropdownToggle class="nav-link arrow-none" id="brandDropdown" href="javascript:void(0)"
                    aria-expanded="false">
                    <i class="dripicons-view-apps noti-icon"></i>
                </a>
                <div ngbDropdownMenu aria-labelledby="brandDropdown"
                    class="dropdown-menu-end dropdown-menu-animated dropdown-lg p-0">
                    <div class="p-2">
                        <div class="row g-0 row-cols-3">
                            <div *ngFor="let app of apps" class="col">
                                <a ngbDropdownItem class="dropdown-icon-item" href="javascript:void(0);">
                                    <img [src]="app.logo" alt="app">
                                    <span>{{app.name}}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </li> -->
            <!-- Theme Settings -->
            <!-- <li class="notification-list">
                <a class="nav-link end-bar-toggle" href="javascript:void(0);" (click)="toggleRightSidebar()">
                    <i class="dripicons-gear noti-icon"></i>
                </a>
            </li> -->
            <li class="dropdown notification-list" ngbDropdown>
                <a class="nav-link nav-user arrow-none me-0" id="profileMenuDropdown" href="javascript:void(0)"
                    aria-expanded="false" *ngIf="!loggedInUser">
                    <!-- <span class="account-user-avatar">
                        <span>
                            <img src="../../../../assets/images/Male-Avatar.jpg" alt="user-avatar"
                                class="rounded-circle">
                        </span>
                    </span>
                    <span>
                        <span class="account-user-name">Guest</span>
                        <span class="account-position">Community Member
                        </span>
                    </span> -->
                    <button class="btn btn-primary" (click)="login()" type="button">Login</button>
                </a>
                <a ngbDropdownToggle class="nav-link nav-user arrow-none me-0" id="profileMenuDropdown"
                    href="javascript:void(0)" aria-expanded="false" *ngIf="loggedInUser">
                    <span class="account-user-avatar">
                        <span *ngIf="loggedInUser.profile_pic">
                            <img [src]="loggedInUser.profile_pic.url" alt="user-image" class="rounded-circle"
                                *ngIf="loggedInUser.profile_pic.url">
                        </span>
                        <span *ngIf="!(loggedInUser.profile_pic)">
                            <img src="../../../../assets/images/Male-Avatar.jpg" alt="user-avatar"
                                class="rounded-circle">
                        </span>
                        <span *ngIf="loggedInUser.profile_pic">
                            <img src="../../../../assets/images/Male-Avatar.jpg" alt="user-avatar"
                                class="rounded-circle" *ngIf="!(loggedInUser.profile_pic.url)">
                        </span>
                    </span>
                    <span>
                        <span class="account-user-name">
                            <span *ngIf="loggedInUser.display_name">{{loggedInUser.display_name}}</span>
                            <span *ngIf="loggedInUser.first_name">{{loggedInUser.first_name}} </span>
                            <span *ngIf="loggedInUser.last_name">{{loggedInUser.last_name}}</span>
                        </span>
                        <span class="account-position" *ngIf="loggedInUser.role">{{loggedInUser.role}}
                            <!-- {{loggedInUser.title}} -->
                        </span>
                    </span>
                </a>
                <div ngbDropdownMenu aria-labelledby="profileMenuDropdown"
                    class="dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
                    <!-- item -->
                    <div ngbDropdownItem class="dropdown-header noti-title">
                        <h6 class="text-overflow m-0" *ngIf="loggedInUser">Welcome
                            <span *ngIf="loggedInUser.display_name">{{loggedInUser.display_name}}</span>!</h6>
                    </div>
                    <!-- item -->
                    <a *ngFor="let option of profileOptions;" class="notify-item" ngbDropdownItem
                        href="javascript:void(0)" (click)="onOptionClick($event,option.redirectTo)">
                        <i class="{{option.icon}} me-1"></i>
                        <span style="cursor: pointer;">{{option.label}}</span>
                    </a>
                    <a class="notify-item" ngbDropdownItem
                        href="javascript:void(0)" (click)="logout()">
                        <i class="mdi mdi-logout me-1"></i>
                        <span style="cursor: pointer;">Logout</span>
                    </a>
                </div>
            </li>
            <!-- <li class="dropdown notification-list">
                <div class="app-search d-none d-xl-block" container="body" placement="bottom-end">
                    <form>
                        <div class="input-group">
                            <div class="input-group-append">
                                <button class="btn btn-primary" (click)="login()" type="button">Login</button>
                            </div>
                        </div>
                    </form>
                </div>
            </li> -->
        </ul>


        <button class="button-menu-mobile open-left d-none d-md-block" (click)="toggleSidebarWidth()"
            *ngIf="layoutType==='vertical' || layoutType==='full'">
            <i class="mdi mdi-menu open-left-icon"></i>
        </button>

        <!-- mobile menu toggle -->
        <button class="button-menu-mobile open-left disable-btn d-md-none" (click)="toggleMobileMenu($event)"
            *ngIf="layoutType==='vertical' || layoutType==='full'">
            <i class="mdi mdi-menu open-left-icon"></i>
        </button>


        <a class="navbar-toggle open-left" [ngClass]="{'open': topnavCollapsed}" (click)="toggleMobileMenu($event)"
            *ngIf="layoutType==='horizontal'">
            <div class="lines">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </a>

        <a class="button-menu-mobile open-left disable-btn" (click)="toggleMobileMenu($event)"
            *ngIf="layoutType==='detached'">
            <div class="lines">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </a>
    </div>

</div>
<!-- Topbar End -->