// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: "AIzaSyA5cp8XABWp9Oe31S8FnAhZft8fAtbDc68",
    authDomain: "adept-depth-410907.firebaseapp.com",
    databaseURL: "https://adept-depth-410907-default-rtdb.firebaseio.com",
    projectId: "adept-depth-410907",
    storageBucket: "adept-depth-410907.appspot.com",
    messagingSenderId: "280253080138",
    appId: "1:280253080138:web:ba75b113c3b5f96980bcb3",
    measurementId: "G-34BG13ZYVD"
  },
  production: true,
  demo: '', // other possible options are, saas creative and modern
  GOOGLE_MAPS_API_KEY: 'AIzaSyC5pcAkhXSo6-KqV9RtZdUAhdJno7r_8b0'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
