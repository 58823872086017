import { MenuItem } from '../models/menu.model';

// menu items
export const MENU: MenuItem[] = [
    { key: 'ds-navigation', label: 'Navigation', isTitle: true },
    { key: 'ds-ask-home', label: 'Home', isTitle: false, icon: 'uil-home-alt', link: '/' },
    { key: 'ds-ask-question', label: 'Ask a question', isTitle: false, icon: 'uil-comment-alt-question', link: '/platform/ask-question' },
    { key: 'ds-question', label: 'Questions', isTitle: false, icon: 'uil-comments', link: '/platform/question-list' },
    // {
    //     key: 'ds-platform', label: 'Tags', isTitle: false, icon: 'uil-tag-alt', collapsed: false,
    //     children: [
    //         //{ key: 'ds-question-list', label: 'Questions', link: '/platform/question-list', parentKey: 'platform' },
    //         // { key: 'ds-question', label: 'Question', link: '/platform/question', parentKey: 'platform' },
    //         // { key: 'ds-answer', label: 'Answer', link: '/platform/answer', parentKey: 'platform' },

    //         { key: 'ds-farming-category', label: 'Category', link: '/platform/farming-category', parentKey: 'platform' },
    //         { key: 'ds-related-projects', label: 'Projects', link: '/platform/related-project', parentKey: 'platform' },
    //         { key: 'ds-location', label: 'Locations', link: '/platform/location', parentKey: 'platform' },
    //         //{ key: 'ds-tags', label: 'Tags', link: '/platform/tags', parentKey: 'platform' },
    //     ]
    // },
    { key: 'question-review', label: 'Pending Review', isTitle: false, icon: 'uil-file-exclamation', link: 'platforms/question-review' },
    // { key: 'user', label: 'Users', isTitle: false, icon: 'uil-users-alt', link: 'platforms/community'},

    //{ key: 'landing', label: 'About', isTitle: false, icon: 'uil-globe', link: '/landing' },
]

export const CLIENT_MENU: MenuItem[] = [
    { key: 'ds-navigation', label: 'Navigation', isTitle: true },
    { key: 'ds-dashboard', label: 'Dashboard', isTitle: false, icon: 'uil-home-alt', link: '/client/dashboard' },
    { key: 'ds-orders', label: 'Orders', isTitle: false, icon: 'uil-home-alt', link: '/client/order' },
    { key: 'ds-payments', label: 'Payments', isTitle: false, icon: 'uil-comment-alt-question', link: '/client/payments' },
    { key: 'ds-invoice', label: 'Account Settings', isTitle: false, icon: 'uil-comments', link: '/client/account-settings' },
    { key: 'ds-profile', label: 'Profile', isTitle: false, icon: 'uil-comments', link: '/client/profile' },
]


export const ADMIN_MENU: MenuItem[] = [
    { key: 'ds-navigation', label: 'Navigation', isTitle: true },
    { key: 'ds-dashboard', label: 'Dashboard', isTitle: false, icon: 'uil-home-alt', link: '/admin/dashboard' },
    { key: 'ds-services', label: 'Services', isTitle: false, icon: 'uil-home-alt', link: '/admin/services' },
    { key: 'ds-company', label: 'Company', isTitle: false, icon: 'uil-comment-alt-question', link: '/admin/company' },
    { key: 'ds-user', label: 'User', isTitle: false, icon: 'uil-comment-alt-question', link: '/admin/user' },
    { key: 'ds-tool', label: 'Tool', isTitle: false, icon: 'uil-comment-alt-question', link: '/admin/tool' },
    { key: 'ds-project', label: 'Project', isTitle: false, icon: 'uil-comment-alt-question', link: '/admin/project' },
]

export const COUPLE_MENU: MenuItem[] = [
    { key: 'ds-navigation', label: 'Navigation', isTitle: true },
    { key: 'ds-dashboard', label: 'Guests', isTitle: false, icon: 'uil-home-alt', link: '/portal/guest-list' },
    { key: 'ds-services', label: 'Gifts', isTitle: false, icon: 'uil-home-alt', link: '/portal/gift-list' },
    { key: 'ds-services', label: 'SMS', isTitle: false, icon: 'uil-home-alt', link: '/portal/sms' },
]