import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, first } from 'rxjs/operators';

//AngularFireAuth
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import * as firebase from 'firebase/compat/app';
import { FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult } from 'firebaseui-angular';

// types
import { User } from '../models/User';
import { empty, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { UserService } from './user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireFunctions } from '@angular/fire/compat/functions';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    user: Observable<firebase.default.User | null>;
    subscription: Subscription = new Subscription();
    returnUrl: any;
    constructor(
        private http: HttpClient,
        private userService: UserService,
        private afAuth: AngularFireAuth,
        private route: ActivatedRoute,
        private router: Router,
        private fns: AngularFireFunctions,
    ) {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.user = afAuth.authState;
    };

    get authStatus(): Observable<any> {
        return this.afAuth.authState;
    };

    /**
     * Returns the email verification state
     */

    get emailVerificationState() {
        return this.user
            .pipe(switchMap((user: any) => {

                if (user) {
                    return user.emailVerified;
                } else {
                    //Navigate to signup
                    return false;
                }
            }));
    };

    verifyEmail() {
        return this.user.pipe().subscribe((user) => {
            return user.sendEmailVerification();
        });
    };

    /**
     * Returns the current user
     */

    get currentUser(): Observable<User | null> {
        return this.user
            .pipe(switchMap(user => {

                if (user) {
                    return this.userService.getUser(user.uid).valueChanges();
                } else {
                    //Navigate to signup
                    return empty();
                }
            }));
    };

    /**
     * Logout the user
     */
    logout(): void {
        // remove user from session storage to log user out
        this.afAuth.signOut();
        sessionStorage.removeItem('currentUser');
        this.user == null;
        //navigate to return url
        localStorage.getItem('returnUrl');

        //this.router.navigate(['/account/login']);
    };

    // successCallback(data: FirebaseUISignInSuccessWithAuthResult) {
    //     if (data) {
    //         if (data.authResult) {
    //             if (data.authResult.user) {
    //                 if (data.authResult.user.metadata) {
    //                     if (data.authResult.user.metadata.creationTime === data.authResult.user.metadata.lastSignInTime) {
    //                         this.userService.initAuthProfileSave(data.authResult.user);
    //                         const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/';
    //                         localStorage.setItem('returnUrl', returnUrl);
    //                         //window.location.href = '/';

    //                     } else {
    //                         this.router.navigate(['/']);
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // };

    errorCallback(data: FirebaseUISignInFailure) {
        // console.warn('errorCallback', data.code);
    };

    uiShownCallback() { };



    // Sign in with email/password
    signInWithEmailAndPassword(email: string, password: string) {
        return this.afAuth
            .signInWithEmailAndPassword(email, password)
            .then((result) => {

                // Save Data ????
                this.setUserData(result.user);

                this.afAuth.authState.subscribe((user) => {
                    if (user) {

                        this.router.navigate([this.returnUrl]);
                        
                        return "Success";
                    }
                });
            })
            .catch((error) => {
                window.alert(error.message);

                // Send to page account/login error = error.message
                this.router.navigate(['account/login'], {queryParams:{m: error.message,r: "sign in"}});
            });
    }

    // Sign up with email/password
    signUp(firstName: string, lastName: string, email: string, password: string) {
        return this.afAuth
            .createUserWithEmailAndPassword(email, password)
            .then((result) => {

                // Email Verification
                this.sendVerificationMail();

                // Save Data
                this.setUserData({
                    email: email,
                    password: password,
                    firstName: firstName,
                    lastName: lastName
                });

                this.router.navigate([this.returnUrl]);
            })
            .catch((error) => {
                window.alert(error.message);

                // Send to page account/login error = error.message
                this.router.navigate(['account/login'], {queryParams:{m: error.message,r: "sign up"}});
            });
    }

    // Send email verfificaiton when new user sign up
    sendVerificationMail() {
        return this.afAuth.currentUser
            .then((u: any) => u.sendEmailVerification())
            .then(() => {

                // Save Data
                this.setUserData({
                    emailVerificationSent: true,
                });

            });
    }

    // Reset Forggot password
    forgotPassword(passwordResetEmail: string) {
        return this.afAuth
            .sendPasswordResetEmail(passwordResetEmail)
            .then(() => {

                // Save Data via backend function
                let data = {
                    passwordReset: {
                        date: "",
                        ip: "",
                    },
                };

                // Toast
                window.alert('Password reset email sent, check your inbox.');
                this.logout();
                this.router.navigate(['account/login'], {queryParams:{m: "Password reset successful!",r: "password reset"}});
            })
            .catch((error) => {
                window.alert(error);

                this.router.navigate(['account/login'], {queryParams:{m: error.message,r: "password reset"}});
            });
    }

    /* Setting up user data when sign in with username/password, 
    sign up with username/password and sign in with social auth  
    provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
    setUserData(user: any) {
        // Save Data 
        this.userService.initAuthProfileSave(user);
    }

    // Sign out
    signOut() {
        return this.afAuth.signOut().then(() => {
            localStorage.removeItem('user');

            // Save Data via backend function
            let data = {
                signOut: {
                    date: "",
                    ip: "",
                },
            };

            // Navigate Home
            this.router.navigate(['account/signout']);
        });
    }

    

}

