import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// auth guard
import { AuthGuard } from './core/guards/auth.guard';

// components
import { PrivateLayoutComponent } from './layouts/private-layout/private-layout.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: "",
    component: PrivateLayoutComponent,
    canActivate: [
      AuthGuard,
      //VerificationGuard
    ],
    children: [
      { path: 'portal', loadChildren: () => import('./pages/couple/couple-route').then(m => m.COUPLE_ROUTES) },
    ]
  },
  {
    path: "",
    component: PublicLayoutComponent,
    children: [
      { path: 'account', loadChildren: () => import('./accounts/accounts-route').then(m => m.ACCOUNTS_ROUTES) },
      { path: 'error', loadChildren: () => import('./pages/error/error-route').then(m => m.ERROR_ROUTES) },
      { path: 'maintenance', loadChildren: () => import('./pages/maintenance/maintenance-route').then(m => m.MAINTENANCE_ROUTES) },
      { path: 'home', loadComponent: () => import('./pages/landing/landing.component').then(m => m.LandingComponent) },
      { path: 'partnership-request', loadComponent: () => import('./pages/partnership-request/partnership-request.component').then(m => m.PartnershipRequestComponent) },
      { path: 'privacy', loadComponent: () => import('./pages/privacy/privacy.component').then(m => m.PrivacyComponent) },
      { path: 'our-story', loadComponent: () => import('./pages/our-story/our-story.component').then(m => m.OurStoryComponent) },
      { path: 'faq', loadComponent: () => import('./pages/faq/faq.component').then(m => m.FaqComponent) },
      { path: 'technology', loadComponent: () => import('./pages/technology/technology.component').then(m => m.TechnologyComponent) },
      { path: 'partners', loadComponent: () => import('./pages/partners/partners.component').then(m => m.PartnersComponent) },
      { path: 'support', loadComponent: () => import('./pages/support/support.component').then(m => m.SupportComponent) },
      { path: 'contact-us', loadComponent: () => import('./pages/contact-us/contact-us.component').then(m => m.ContactUsComponent) },
    ]
  },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes, {
    anchorScrolling: 'enabled',
    //  enableTracing: true //<-- debugging purposes only
    //  ,
    //relativeLinkResolution: 'legacy'
  }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
