export const environment = {
  firebase: {
    apiKey: "AIzaSyA5cp8XABWp9Oe31S8FnAhZft8fAtbDc68",
    authDomain: "adept-depth-410907.firebaseapp.com",
    databaseURL: "https://adept-depth-410907-default-rtdb.firebaseio.com",
    projectId: "adept-depth-410907",
    storageBucket: "adept-depth-410907.appspot.com",
    messagingSenderId: "280253080138",
    appId: "1:280253080138:web:ba75b113c3b5f96980bcb3",
    measurementId: "G-34BG13ZYVD"
  },
  production: true,
  demo: '', // other possible options are, saas creative and modern
  GOOGLE_MAPS_API_KEY: 'AIzaSyC5pcAkhXSo6-KqV9RtZdUAhdJno7r_8b0'
};
